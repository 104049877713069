import { DownloadApp, EventsAndPartiesInformationPage, FooterGlobal, Nav } from 'components';

export function EventsAndPartiesPage() {
  return (
    <>
      <Nav />
      <EventsAndPartiesInformationPage />
      <DownloadApp />
      <FooterGlobal />
    </>
  );
}
