import './assets/css/stylesHowWeUseYourDonation.css';

export function HowWeUseYourDonation() {

    return (
        <section className='lSectionHowWeUseYourDonation' id='sectionHowWeUseYourDonation'>
            <div className='sectionHowWeUseYourDonation'>
                <div className='sectionHowWeUseYourDonationData'>
                    <div className='divTitleHowWeUseYourDonation'>
                        <h2 className='globalSectionTitleTextDark45px'>
                            Como usamos sua doação
                        </h2>
                    </div>
                    <div className='divTextStylesHowWeUseYourDonation'>
                        <p className='textStylesHowWeUseYourDonation'>
                            Parte das doações é destinada à preparação de marmitas, que são distribuídas para <strong>pessoas em situação de rua</strong>, proporcionando-lhes uma refeição quente e nutritiva.
                        </p>

                        <p className='textStylesHowWeUseYourDonation'>
                            Também apoiamos <strong>projetos sociais</strong>, como o Projeto <strong>Rede de Proteção da Criança</strong> do Morro do Meio e o <strong>Lar Reviver</strong>, que protegem e apoiam crianças em situação de risco.
                        </p>
                    </div>
                    <div className='divTextStylesHowWeUseYourDonation'>
                        <p className='textStylesHowWeUseYourDonation'>
                            Além disso, nossas doações ajudam comunidades vulneráveis, como a comunidade de dependentes químicos <strong>CRAP</strong>, oferecendo recursos para recuperação e reintegração social.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
